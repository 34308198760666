import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/shell/seo"
import Layout from "../../components/shell/layout"
import BlogDetail from "../../components/blog/pageContents/BlogDetail"

function BlogDetailWrapper({ data }) {
  let tagsData = []
  data.blogJson.tags.forEach(item => {
    data.allBlogJson.nodes.forEach(i => {
      if (i.tags.indexOf(item) !== -1) {
        if (tagsData.indexOf(i) === -1) {
          tagsData.push(i)
        }
      }
    })
  })

  const imgSrc = data.blogJson.img.src.absolutePath.split("/")
  const imgName = imgSrc[imgSrc.length - 1]
  const img = require("../../media/blog/" + imgName)
  return (
    <Layout>
      <SEO title="Tin Tức" description={data.blogJson.news} img={img} />
      <BlogDetail data={data.blogJson} otherNews={data.allBlogJson.nodes} />
    </Layout>
  )
}

export default BlogDetailWrapper

export const query = graphql`
  query($id: String) {
    blogJson(id: { eq: $id }) {
      author
      tags
      content
      date
      id
      news
      summary
      title
      img {
        alt
        src {
          absolutePath
          childImageSharp {
            fluid {
              srcSet
            }
          }
        }
      }
    }
    allBlogJson(
      filter: { id: { ne: $id } }
      sort: { fields: order, order: DESC }
    ) {
      nodes {
        date
        tags
        title
        news
        id
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        summary
        content
        author
      }
    }
  }
`
